class ProductModel {
    productId!: number;
    productTitle!: string;
    productImageUrl!: string;
    productPrice!: number;
    productShipping!: boolean;
    productType!: string;
    requestGuid!: number;
    sourceCodeId!: number;
    isPromoEnabled!: boolean;
  }
  
  export default ProductModel;