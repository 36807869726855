import React, { useState } from 'react'
import { omit } from 'lodash'

const useForm = (callback: any) => {

    //Form values
    const [values, setValues] = useState<any>({});
    //Errors
    const [errors, setErrors] = useState<any>({});


    const validate = (name: any, value: any) => {
        //A function to validate each input values

        switch (name) {
            case 'firstName':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        firstName: [
                            {
                                label: 'First name is required',
                                labelId: 'input-id-error-fn'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "firstName");
                    setErrors(newObj);
                }
                break;

            case 'lastName':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        lastName: [
                            {
                                label: 'Last name is required',
                                labelId: 'input-id-error-ln'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "lastName");
                    setErrors(newObj);
                }
                break;

            case 'email':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        email: [
                            {
                                label: 'Email is required',
                                labelId: 'input-id-error-email'
                            }
                        ]
                    })
                } else if (!new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) {
                    setErrors({
                        ...errors,
                        email: [
                            {
                                label: 'Enter a valid email address',
                                labelId: 'input-id-error-email'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                }
                break;

            case 'password':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        password: [
                            {
                                label: 'Password is required',
                                labelId: 'input-id-error-password'
                            }
                        ]
                    })
                } else if (!new RegExp(/^(?=(.*[a-z])+)(?=(.*[A-Z])+)(?=(.*[0-9])+)(?=(.*[!@#$%^&amp;*()\-__+.])+).{8,}$/).test(value)) {
                    setErrors({
                        ...errors,
                        password: [
                            {
                                label: 'Password should contains atleast 8 charaters and containing uppercase,lowercase,numbers and special characters',
                                labelId: 'input-id-error-password'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "password");
                    setErrors(newObj);
                }
                break;

            case 'designation':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        designation: [
                            {
                                label: 'Designation is required',
                                labelId: 'input-id-error-designation'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "designation");
                    setErrors(newObj);
                }
                break;

            case 'speciality':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        speciality: [
                            {
                                label: 'Speciality is required',
                                labelId: 'input-id-error-speciality'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "speciality");
                    setErrors(newObj);
                }
                break;

            case 'address':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        address: [
                            {
                                label: 'Address is required',
                                labelId: 'input-id-error-address'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "address");
                    setErrors(newObj);
                }
                break;

            case 'country':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        country: [
                            {
                                label: 'Country is required',
                                labelId: 'input-id-error-country'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "country");
                    setErrors(newObj);
                }
                break;

            case 'state':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        state: [
                            {
                                label: 'State is required',
                                labelId: 'input-id-error-state'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "state");
                    setErrors(newObj);
                }
                break;

            case 'city':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        city: [
                            {
                                label: 'City is required',
                                labelId: 'input-id-error-city'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "city");
                    setErrors(newObj);
                }
                break;

            case 'zipcode':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        zipcode: [
                            {
                                label: 'Zipcode is required',
                                labelId: 'input-id-error-zipcode'
                            }
                        ]
                    })
                } else if (value.length !== 5) {
                    setErrors({
                        ...errors,
                        zipcode: [
                            {
                                label: 'Zipcode should be 5 digits',
                                labelId: 'input-id-error-zipcode'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "zipcode");
                    setErrors(newObj);
                }
                break;

            case 'phoneNumber':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        phoneNumber: [
                            {
                                label: 'Phone number is required',
                                labelId: 'input-id-error-phoneNumber'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "phoneNumber");
                    setErrors(newObj);
                }
                break;

                case 'billingAddress':
                    if (value.length === 0) {
                        setErrors({
                            ...errors,
                            billingAddress: [
                                {
                                    label: 'Address is required',
                                    labelId: 'input-id-error-billingAddress'
                                }
                            ]
                        })
                    } else {
                        let newObj = omit(errors, "billingAddress");
                        setErrors(newObj);
                    }
                    break;
    
                case 'billingCountry':
                    if (value.length === 0) {
                        setErrors({
                            ...errors,
                            billingCountry: [
                                {
                                    label: 'Country is required',
                                    labelId: 'input-id-error-billingCountry'
                                }
                            ]
                        })
                    } else {
                        let newObj = omit(errors, "billingCountry");
                        setErrors(newObj);
                    }
                    break;
    
                case 'billingState':
                    if (value.length === 0) {
                        setErrors({
                            ...errors,
                            billingState: [
                                {
                                    label: 'State is required',
                                    labelId: 'input-id-error-billingState'
                                }
                            ]
                        })
                    } else {
                        let newObj = omit(errors, "billingState");
                        setErrors(newObj);
                    }
                    break;
    
                case 'billingCity':
                    if (value.length === 0) {
                        setErrors({
                            ...errors,
                            billingCity: [
                                {
                                    label: 'City is required',
                                    labelId: 'input-id-error-billingCity'
                                }
                            ]
                        })
                    } else {
                        let newObj = omit(errors, "billingCity");
                        setErrors(newObj);
                    }
                    break;
    
                case 'billingZipcode':
                    if (value.length === 0) {
                        setErrors({
                            ...errors,
                            billingZipcode: [
                                {
                                    label: 'Zipcode is required',
                                    labelId: 'input-id-error-billingZipcode'
                                }
                            ]
                        })
                    } else if (value.length !== 5) {
                        setErrors({
                            ...errors,
                            billingZipcode: [
                                {
                                    label: 'Zipcode should be 5 digits',
                                    labelId: 'input-id-error-billingZipcode'
                                }
                            ]
                        })
                    } else {
                        let newObj = omit(errors, "billingZipcode");
                        setErrors(newObj);
                    }
                    break;
    
                case 'billingPhoneNumber':
                    if (value.length === 0) {
                        setErrors({
                            ...errors,
                            billingPhoneNumber: [
                                {
                                    label: 'Phone number is required',
                                    labelId: 'input-id-error-phoneNumber'
                                }
                            ]
                        })
                    } else {
                        let newObj = omit(errors, "billingPhoneNumber");
                        setErrors(newObj);
                    }
                    break;

            case 'month':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        month: [
                            {
                                label: 'Month is required',
                                labelId: 'input-id-error-month'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "month");
                    setErrors(newObj);
                }
                break;

            case 'year':
                if (value.length === 0) {
                    setErrors({
                        ...errors,
                        year: [
                            {
                                label: 'Year is required',
                                labelId: 'input-id-error-year'
                            }
                        ]
                    })
                } else {
                    let newObj = omit(errors, "year");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }

    //Initialize state with default values
    const initializeState = () => {
        setValues({
            ...values,
            ['chkaddress']: '1',
            ['month']: '01',
            ['year']: '2022'
        })
    }

    //A method to handle form inputs
    const handleChange = (event: any) => {
        //To stop default events    
        event.persist();

        let name = event.target.name;
        let val = event.target.value;

        validate(name, val);

        if(val === '1')
        {
            let newObj = omit(errors, ["billingAddress", "billingCountry", "billingState", "billingCity", "billingZipcode", "billingPhoneNumber"]);
            setErrors(newObj);
        }

        //Let's set these values in state
        setValues({
            ...values,
            [name]: val,
        })
    }

    //Clear speciality & state
    const handleSelects = (name: any) => {
        setValues({
            ...values,
            [name]: '',
        })
    }

    const handleSubmit = (event: any) => {
        if (event) event.preventDefault();

        let isValid = validateForm();

        if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0 && isValid) {
            callback();
        } else {
            // alert("There is an Error!");
        }
    }

    const validateForm = () => {
        let error = {}, isValid = true;

        if (!values.firstName) {
            error = {
                firstName: [
                    {
                        label: 'First name is required',
                        labelId: 'input-id-error-fn'
                    }
                ]
            };
            isValid = false;
        }
        if (!values.lastName) {
            error = Object.assign({
                lastName: [
                    {
                        label: 'Last name is required',
                        labelId: 'input-id-error-ln'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (!values.email) {
            error = Object.assign({
                email: [
                    {
                        label: 'Email is required',
                        labelId: 'input-id-error-email'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.password === undefined) {
            error = Object.assign({
                password: [
                    {
                        label: 'Password is required',
                        labelId: 'input-id-error-password'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.designation === undefined) {
            error = Object.assign({
                designation: [
                    {
                        label: 'Designation is required',
                        labelId: 'input-id-error-designation'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.speciality === undefined) {
            error = Object.assign({
                speciality: [
                    {
                        label: 'Speciality is required',
                        labelId: 'input-id-error-speciality'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.address === undefined) {
            error = Object.assign({
                address: [
                    {
                        label: 'Address is required',
                        labelId: 'input-id-error-address'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.country === undefined) {
            error = Object.assign({
                country: [
                    {
                        label: 'Country is required',
                        labelId: 'input-id-error-country'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.state === undefined) {
            error = Object.assign({
                state: [
                    {
                        label: 'State is required',
                        labelId: 'input-id-error-state'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.city === undefined) {
            error = Object.assign({
                city: [
                    {
                        label: 'City is required',
                        labelId: 'input-id-error-city'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.zipcode === undefined) {
            error = Object.assign({
                zipcode: [
                    {
                        label: 'Zipcode is required',
                        labelId: 'input-id-error-zipcode'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.phoneNumber === undefined) {
            error = Object.assign({
                phoneNumber: [
                    {
                        label: 'Phone number is required',
                        labelId: 'input-id-error-phoneNumber'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.billingAddress === undefined && values.chkaddress === '2') {
            error = Object.assign({
                billingAddress: [
                    {
                        label: 'Address is required',
                        labelId: 'input-id-error-billingAddress'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.billingCountry === undefined && values.chkaddress === '2') {
            error = Object.assign({
                billingCountry: [
                    {
                        label: 'Country is required',
                        labelId: 'input-id-error-billingCountry'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.billingState === undefined && values.chkaddress === '2') {
            error = Object.assign({
                billingState: [
                    {
                        label: 'State is required',
                        labelId: 'input-id-error-billingState'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.billingCity === undefined && values.chkaddress === '2') {
            error = Object.assign({
                billingCity: [
                    {
                        label: 'City is required',
                        labelId: 'input-id-error-billingCity'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.billingZipcode === undefined && values.chkaddress === '2') {
            error = Object.assign({
                billingZipcode: [
                    {
                        label: 'Zipcode is required',
                        labelId: 'input-id-error-billingZipcode'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.billingPhoneNumber === undefined && values.chkaddress === '2') {
            error = Object.assign({
                billingPhoneNumber: [
                    {
                        label: 'Phone number is required',
                        labelId: 'input-id-error-billingPhoneNumber'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.month === undefined) {
            error = Object.assign({
                month: [
                    {
                        label: 'Month is required',
                        labelId: 'input-id-error-month'
                    }
                ]
            }, error);
            isValid = false;
        }
        if (values.year === undefined) {
            error = Object.assign({
                year: [
                    {
                        label: 'Year is required',
                        labelId: 'input-id-error-year'
                    }
                ]
            }, error);
            isValid = false;
        }


        if (error) {
            setErrors({
                ...errors,
                ...error
            })
        }

        return isValid;
    }


    return {
        values,
        errors,
        initializeState,
        handleChange,
        handleSubmit,
        handleSelects
    }
}

export default useForm