import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className="page-footer font-small bg-grey">
                <main role="main" className="wk-page-container">
                    <div className="footer-container wk-row paypal d-block">
                        <div className="row pt-4">
                            <div className="col-md-8 col-sm-12">
                                <a href="index.html">
                                    <img src="https://cdn.wolterskluwer.io/wk/fundamentals/1.x.x/logo/assets/white-large.svg" alt="Wolters Kluwer white logo" />
                                </a>
                            </div>
                            <div className="col-md-4 col-sm-12 text-right">
                                <h3 className="h3-title">Customer Service</h3>
                                <h6 className=" white-color">800.501.2303 USA AND CANADA </h6>
                                <h6 className=" white-color">818.844.3299 EXT. XXX INTERNATIONAL</h6>
                                <h6 className=" white-color"> <a href="mailto:support@ondemand.org" className="emailhref"> CLINICALPULSE@LWW.COM </a></h6>
                                <h6 className=" white-color"> PRIVACY POLICY </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <span className="copyright">
                                    <sup>®</sup> 2022 Wolters Kluwer N.V. and/or its subsidiaries. All rights reserved.
                                </span>
                            </div>
                            <div className="col-sm-4 text-right">
                                <a href="index.html">
                                    <img src="https://cdn.wolterskluwer.io/wk/fundamentals/1.x.x/logo/assets/white-large.svg" alt="Wolters Kluwer white logo" />
                                </a>
                            </div>
                        </div>
                        <div style={{color:'#757575'}}>
                            Application Version: 22.5
                            Application Server name:10.0.3.175
                        </div>
                    </div>
                </main>
            </footer>
        </>
    )
}

export default Footer;