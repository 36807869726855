import http from '../httpService';

class ProductService {
    public async GetProductDetails(tid: string | null, cid: string | null) {
        if (tid != null && cid != null) {
            let result = await http.get(`api/v1/productcart/details?tid=${tid}&cid=${cid}`);
            return result.data;
        }
    }
}

export default new ProductService();