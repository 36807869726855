import React from 'react';
import { BrowserRouter } from "react-router-dom";
import './App.css';
import AppLayout from './components/layout/AppLayout';

declare var blowfish: any;

function App() {
  return (
    <BrowserRouter>
      <AppLayout />
    </BrowserRouter>
  );
}

export default App;
