import React from 'react';

const ProductsInfo = ({ title, basePrice, shippingPrice, salesTax, shippingTax, totalPrice }: any) => {
    const price = basePrice > 0 ? '$' + basePrice : '';
    return (
        <div className=" wk-col-6-laptop wk-col-12-tablet wk-col-6-hd float-right col-sm-12 col-md-6 col-lg-6">
            <div className="row">
                <div className="col-sm-1-custom"></div>
                <div className="col-md-11 col-sm-12">
                    <div className="wk-docs-example-card-container">
                        <div className="wk-row">
                            <header className="w-100 wk-border-bottom wk-mt-26 wk-navbar-menu wk-text-left">
                                <h3 className="wk-text-left create-your-account">Product Information</h3>
                            </header>
                        </div>
                        <div className="wk-row">
                            <div className="w-100 wk-border-bottom wk-mt-26 wk-navbar-menu wk-text-left">
                                <div className="d-md-flex flex-md-wrap">
                                    <div className="mb-4 mb-md-2">
                                        <ul className="list list-unstyled mb-0">
                                            <li>{title}</li>
                                        </ul>
                                    </div>
                                    <div className="mb-2 ml-auto">
                                        <div className="d-flex flex-wrap wmin-md-200">
                                            <ul className="list list-unstyled text-right mb-0 ml-auto productprice">
                                                <li className="font-weight-normal">{price}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wk-row">
                            <div className="d-md-flex flex-md-wrap w-100" >
                                <div className="mb-2 ml-auto ">
                                    <div className="d-flex flex-wrap wmin-md-200">
                                        <ul className="list list-unstyled mb-0 text-right productdetails">
                                            <li className="productcustomlabel font-weight-bold" id="discountLabel"></li>
                                            <li className="productcustomlabel productcustomlabelmr-t-04x" id="shippingLabel">Shipping & Handling:</li>
                                            <li className="productcustomlabel productcustomlabelmr-t-04x" id="salesTaxLabel">Sales Tax:</li>
                                            <li className="productcustomlabel productcustomlabelmr-t-04x" id="shippingTaxLabel">Shipping Tax:</li>
                                            <li style={{ fontWeight: 'bold' }} className="productcustomlabel productcustomlabelmr-t-04" id="totalLabel">Order Total:</li>
                                        </ul>
                                        <ul className="list list-unstyled wk-text-right mb-0 ml-auto productprice">
                                            <li className="font-weight-normal wk-text-right" id="discountPrice"></li>
                                            <li className="font-weight-normal wk-text-right" id="shippingPrice">{shippingPrice || '$0.00'}</li>
                                            <li className="font-weight-normal wk-text-right" id="salesPrice">{salesTax || '$0.00'}</li>
                                            <li className="font-weight-normal wk-text-right" id="shippingTaxPrice">{shippingTax || '$0.00'}</li>
                                            <li className="font-weight-bold productcustomlabel productcustomlabelmr-t-04 text-right" id="order-total">{totalPrice || price}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1-custom"></div>
            </div>
        </div>
    );
};

export default ProductsInfo;