
    
const ThankYou = () => (
    <div className="wk-row paypal d-block pb-4 bg-white">
        <div className=" wk-col-6-laptop wk-col-12-tablet wk-col-6-hd col-sm-12 col-md-12 col-lg-12 pb-2">
            <div className="row pb-4 mb-4 thank-you-body">

                <div className="col-md-7 col-sm-12 m-auto ">
                    <div className="wk-docs-example-card-container">

                        <header className="w-100  wk-navbar-menu wk-text-left">
                            <h3 className="thank-you-hedding text-center mb-4 mt-2 pt-4"> THANK YOU FOR YOUR ORDER  </h3>
                        </header>
                        <p>You will receive an email with your receipt and an access link for your product shortly.Please be sure to check your junk and spam folders just in case the email is filtered out of your inbox.
                        </p>
                        <p className="pb-4">  Get started by downloading the mobile app.</p>
                        <div className="row text-center m-auto" style={{ maxWidth: 450 }}>
                            <a href={process.env.REACT_APP_APPLE_STORE_URL} className=" mb-4 pb-3 dm-auto">
                                <img src="image/App.png" alt="Wolters Kluwer white logo" style={{ maxWidth: 200 }} />
                            </a>
                            <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} className="dm-auto mb-4 pb-3">
                                <img src="image/appgoogle.png" alt="Wolters Kluwer white logo" style={{ maxWidth: 200 }} />
                            </a>
                        </div>
                        <p className="pt-4">  If you are not completely satisfied with your Lippincott® ClinicalPulse product, you may cancel within 14 business days of purchase and receive a prompt refund.</p>
                        <p> Please contact Customer Service at 800-423-2308 ext.XXX or <a href="mailto:clinicalpulse@lww.com" className="emailhref">clinicalpulse@lww.com</a>  if you need assistance.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default ThankYou;