class AddressModel {
    address!: string;
    address2!: string;
    city!: string;
    state!: string;
    country!: string;
    zipcode!: string;
    phoneNumber!: string;
  }
  
  export default AddressModel;