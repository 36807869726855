import axios from 'axios';

// Add a request interceptor
axios.interceptors.request.use(
    function (config: any) {
        config.baseURL = 'https://qa-services.ondemand.org/';
        // config.headers.common['Access-Control-Allow-Origin'] = '*';
        // config.headers.common['Access-Control-Allow-Credentials']= 'true';
        // config.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch
};