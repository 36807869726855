import http from '../httpService';

class PaymentService {
    public async GetPublicKey() {
      let result = await http.get(`api/v1/cybersource/publickey`);
      return result.data;
    }

    public MakePayment(payment: any) {
      let result = http.post('api/v1/productcart/submit', payment);
      return result;
    }
}

export default new PaymentService();