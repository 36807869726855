import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Payment from '../payment/index'
import UserModel from '../../models/userModel';
import AddressModel from '../../models/addressModel';
import Header from '../header';
import Footer from '../footer';
import ThankYou from '../thankyou';

const AppLayout = () => {
    const user = new UserModel();
    const address = new AddressModel();
    return (
        <>
        <Header/>
        <Routes>
            <Route path="/" element={<Payment userDetails={user} addressDetails={address}/>} />
            <Route path="thankyou" element={<ThankYou />} />
        </Routes>
        <Footer/>
        </>
    )
}

export default AppLayout;