export const embedFlexMicroformScript = (scriptSrc) => {
    if (!window.Flex) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://testflex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js';
            script.onload = () => resolve();
            script.onerror = (err) => reject(err.message);
            document.head.append(script);
        });
    }
    return Promise.resolve();
};