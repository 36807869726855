import React from 'react';

const Header = () => {
    return (
        <>
            <main role="main" className="wk-page-container bg-white">
                <div className="wk-row row  pt-2 pb-2">
                    {/* Artificial wrapper for auto-hiding functionality, should be removed from final usage */}
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="wk-banner-container">
                            <a className="wk-logo-container" href="#" title="Go to Wolters Kluwer Product X home page">
                                <img className="wk-logo wk-logo-medium" src="https://cdn.wolterskluwer.io/wk/fundamentals/1.x.x/logo/assets/white-wheel-medium.svg" alt="Go to Wolters Kluwer Product X home page" />
                                <img className="wk-logo wk-logo-small" src="https://cdn.wolterskluwer.io/wk/fundamentals/1.x.x/logo/assets/wheel-small.svg" alt="Go to Wolters Kluwer Product X home page" />
                                <span className="wk-banner-product-name">Lippincott</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <p className="wk-banner-action-bar-item m-0 wk-text-right">
                            Contact Sales: 800-501-2303
                        </p>
                    </div>
                </div>
            </main>

            <div className="wk-row" style={{ marginLeft: '0' }}>
                <main role="main" className="wk-page-container wk-navbar-content">
                    <div className="wk-row" style={{ height: "8px" }}>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Header;