import http from '../httpService';

class DesignationService {
    public async GetDesignations() {
      let result = await http.get(`api/v1/utility/designations`);
      return result.data;
    }

    public async GetSpecialities(designationId: string) {
      let result = await http.get(`api/v1/utility/specialities-by-designation?designId=${designationId}`);
      return result.data;
    }
}

export default new DesignationService();