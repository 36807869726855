import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import Product from '../../components/product';
import PaymentModel from '../../models/paymentModel';
import ProductModel from '../../models/productModel';
import ProductTaxModel from '../../models/productTaxModel';
import productService from '../../services/product/productService';
import countryService from '../../services/country/countryService'
import stateService from '../../services/state/stateService';
import taxService from '../../services/tax/taxService';
import paymentService from '../../services/payment/paymentService';
import designationService from '../../services/designation/designationService';
import { embedFlexMicroformScript } from "./embedFlexMicroform";
import { InputField, ButtonField, RadioField, RadioGroupField, SelectField } from '@wk/components-react16';
import useForm from '../hooks/useForm';
import '@wk/components-react16/dist/forms.css';
import './styles.scss';

declare global {
    interface Window {
        Flex: any;
    }
}

const Payment = ({ userDetails, addressDetails }: PaymentModel) => {
    let navigate = useNavigate(); 
    const [searchParams] = useSearchParams();
    const tid = searchParams.get('tid');
    const cid = searchParams.get('cid');
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(11), (val, index) => (index + year).toString());

    const [product, setProduct] = useState(new ProductModel());
    const [productTax, setProductTax] = useState(new ProductTaxModel());
    const [country, setCountry] = useState<any>([]);
    const [state, setState] = useState<any>([]);
    const [designation, setDesignation] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    const [licensure, setLicensure] = useState([]);
    const [microform, setMicroform] = useState<any>(null);
    const [errorCC, seterrorCC] = useState([]);
    const [errorCVV, seterrorCVV] = useState([]);
    const ccNumberRef = useRef(null);
    const cvvRef = useRef(null);

    const loadFlexMicroform = () => {
        embedFlexMicroformScript()
            .then(async () => {
                const key = await paymentService.GetPublicKey();
                const flex = new window.Flex(key);
                setMicroform(flex.microform({}));
            })
            .catch(window.console.error);
    }

    const setupFlexMicroform = () => {
        if (!microform) {
            return;
        }

        const number = microform.createField("number", {
            placeholder: "Enter Your Credit Card Number", required: true
        });
        const securityCode = microform.createField("securityCode", {
            placeholder: "CVV", required: true
        });

        // load microform elements
        number.load(ccNumberRef.current);
        securityCode.load(cvvRef.current);
    };

    const GetProductDetails = async () => {
        try {
            const product: ProductModel = await productService.GetProductDetails(tid, cid);
            setProduct(product);
        }
        catch (e) {
            console.log(e)
        }
    }

    const getCountries = async () => {
        try {
            const countries = await countryService.getCountries();
            setCountry(countries);
        }
        catch (e) {
            console.log(e)
        }
    }

    const getStates = async (id: number) => {
        try {
            const states = await stateService.getStates(id);
            setState(states);
        }
        catch (e) {
            console.log(e)
        }
    }

    const getDesignations = async () => {
        try {
            const designations = await designationService.GetDesignations();
            setDesignation(designations);
        }
        catch (e) {
            console.log(e)
        }
    }

    const getSpecialities = async (designationId: string) => {
        try {
            const specialities = await designationService.GetSpecialities(designationId);
            setSpecialities(specialities);
        }
        catch (e) {
            console.log(e)
        }
    }

    const getLicensure = async () => {
        try {
            const licensure = await stateService.getStates(192);
            setLicensure(licensure);
        }
        catch (e) {
            console.log(e)
        }
    }

    const calculateTax = async () => {
        try {
            const taxCalculator = {
                countryId: +values.country,
                stateId: +values.state,
                zipcode: values.zipcode,
                productId: product.productId,
                price: product.productPrice,
                productShipping: product.productShipping,
                productType: product.productType
            }
            //const calculationDetails = await taxService.calculateTax(taxCalculator);
            const calculationDetails : any = {
                basePrice: "$920.00",
                hasTax: false,
                salesTax: "$0.00",
                shippingPrice: "$45.00",
                shippingTax: "$0.00",
                total: "$965.00"    
            }
            setProductTax(calculationDetails);
        }
        catch (e) {
            console.log(e)
        }
    }

    const makePayment = async (payment: any) => {
        try {
            const result = await paymentService.MakePayment(payment);
            return result;
            console.log(result);
        }
        catch (e) {
            console.log(e)
        }
    }

    const formSubmit = () => {
        try {
            const totalAmt = productTax.total.toString().replace('$', '');

            const options = {
                expirationMonth: values.month,
                expirationYear: values.year
            };

            microform.createToken(options, async function (err: any, token: any) {
                if (err) {
                    console.log(err);
                    // TODO: need to handle error
                } else {
                    const flexToken = JSON.stringify(token);

                    const mailingAddress = {
                        Address: values.address,
                        Address2: values.address2 || '',
                        Locality: values.city,
                        StateCode: values.state,
                        StateName: state.find((s:any) => s.id == values.state).name,
                        CountryId: values.country,
                        Country: country.find((c:any) => c.id == values.country).name,
                        PostalCode: values.zipcode,
                        IsShippingAddress: false,
                        PhoneNumber: values.phoneNumber
                    }

                    const addressDetails = [mailingAddress];

                    if (values.chkaddress === '2') {
                        const shippingAddress = {
                            Address: values.billingAddress,
                            Address2: values.billingAddress2 || '',
                            Locality: values.billingCity,
                            StateCode: values.billingState,
                            StateName: state.find((s:any) => s.id == values.billingState).name,
                            CountryId: values.billingCountry,
                            Country: country.find((c:any) => c.id == values.billingCountry).name,
                            PostalCode: values.billingZipcode,
                            IsShippingAddress: true,
                            PhoneNumber: values.billingPhoneNumber
                        }

                        addressDetails.push(shippingAddress);
                    }

                    const userDetails = {
                        FirstName: values.firstName,
                        MiddleName: values.middleName || '',
                        LastName: values.lastName,
                        Email: values.email,
                        ProfDesignationId: values.designation,
                        Speciality: values.speciality,
                        StateOfLicensure: values.licensure || '',
                        Addresses: addressDetails
                    };

                    const paymentDetails = {
                        RequestGuid: product.requestGuid,
                        Password: values.password,
                        user: userDetails,
                        TotalAmount: +totalAmt,
                        FlexJwtToken: flexToken,
                        ThinkProductId: product.productId,
                        ProductName: product.productTitle,
                        clientId: 1
                    }

                    const result: any = await makePayment(paymentDetails);
                    console.log(result);
                    if(result.status === 'AUTHORIZED'){
                        let path = `/thankyou`; 
                        navigate(path);
                    }
                }
            });
        }
        catch (e) {
            console.log(e)
        }
    };

    //Custom hook call
    const { handleChange, values, errors, initializeState, handleSubmit, handleSelects } = useForm(formSubmit);

    useEffect(() => {
        initializeState();
        GetProductDetails();
        getCountries();
        getDesignations();
        getLicensure();
        loadFlexMicroform();
    }, [])

    useEffect(() => {
        setupFlexMicroform();
    }, [microform]);

    useEffect(() => {
        if (values.zipcode && values.state) {
            calculateTax();
        }
    }, [values.state, values.zipcode]);

    useEffect(() => {
        if (values.designation) {
            getSpecialities(values.designation);
            handleSelects('speciality');
        } else {
            setSpecialities([]);
        }
    }, [values.designation]);

    useEffect(() => {
        if (values.country) {
            getStates(values.country);
            handleSelects('state');
        } else {
            setState([]);
        }
    }, [values.country]);

    return (
        <>
            <main role="main" className="wk-page-container" style={{ background: '#ededed' }}>
                <div className="wk-row paypal d-block" style={{ background: '#fff' }}>
                    <Product title={product.productTitle} basePrice={product.productPrice} shippingPrice={productTax.shippingPrice} salesTax={productTax.salesTax} shippingTax={productTax.shippingTax} totalPrice={productTax.total} />

                    <form onSubmit={handleSubmit}>
                        <div className="wk-col-6-laptop wk-col-12-tablet wk-col-6-hd wk-border-right col-sm-12 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-sm-1-custom"></div>
                                <div className="col-md-11 col-sm-12">
                                    <div className="wk-docs-example-card-container">
                                        <div className="wk-row">
                                            <header className="w-100 wk-border-bottom wk-mt-26 wk-navbar-menu wk-text-left">
                                                <h3 className="wk-text-left create-your-account">Create Your Account</h3>
                                            </header>
                                        </div>

                                        <div className="wk-row mt-4">
                                            <div className="col-sm-12 col-md-4 col-lg-4 pl-0 sm-pr-0">
                                                <div className="wk-field">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-fn">First Name*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <InputField errors={errors.firstName} status={errors.firstName ? 'error' : 'none'} >
                                                            <input
                                                                type="text"
                                                                id="input-id-pay-fn"
                                                                name="firstName"
                                                                placeholder="First name"
                                                                className="wk-input-border wk-field-input"
                                                                value={values.firstName || ''}
                                                                onChange={handleChange}
                                                            />
                                                        </InputField>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4 col-lg-4 pl-0 sm-pr-0">
                                                <div className="wk-field wk-field-with-tip">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-mn">Middle Name</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <InputField status={errors.middleName ? 'error' : 'none'} >
                                                            <input
                                                                type="text"
                                                                id="input-id-pay-mn"
                                                                name="middleName"
                                                                placeholder="Middle name"
                                                                className="wk-input-border wk-field-input"
                                                                value={values.middleName || ''}
                                                                onChange={handleChange}
                                                            />
                                                        </InputField>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-sm-12 col-md-4 col-lg-4 pl-0 pr-0">
                                                <div className="wk-field">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-ln">Last Name*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <InputField errors={errors.lastName} status={errors.lastName ? 'error' : 'none'} >
                                                            <input
                                                                type="text"
                                                                id="input-id-pay-ln"
                                                                name="lastName"
                                                                placeholder="Last name"
                                                                className="wk-field-input"
                                                                value={values.lastName || ''}
                                                                onChange={handleChange}
                                                            />
                                                        </InputField>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wk-row mt-2">
                                            <div className="wk-field w-100">
                                                <div className="wk-field-header">
                                                    <label className="wk-field-label" htmlFor="input-id-pay-email">Email Address*</label>
                                                </div>
                                                <div className="wk-field-body">
                                                    <div className='wk-input-width'>
                                                        <InputField errors={errors.email} status={errors.email ? 'error' : 'none'} >
                                                            <input
                                                                type="email"
                                                                id="input-id-pay-email"
                                                                name="email"
                                                                placeholder="Email address (this will be your username)"
                                                                className="wk-field-select wk-input-border"
                                                                value={values.email || ''}
                                                                onChange={handleChange}
                                                            />
                                                        </InputField>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wk-row mt-2">
                                            <div className="wk-field wk-field-password  w-100">
                                                <div className="wk-field-header">
                                                    <label className="wk-field-label" htmlFor="input-id-pay-password">Password*</label>
                                                </div>
                                                <div className="wk-field-body">
                                                    <div className='wk-input-width'>
                                                        <InputField errors={errors.password} status={errors.password ? 'error' : 'none'} >
                                                            <input
                                                                type="password"
                                                                id="input-id-pay-password"
                                                                name="password"
                                                                placeholder="Password"
                                                                className="wk-field-select wk-input-border"
                                                                value={values.password || ''}
                                                                onChange={handleChange}
                                                            />
                                                        </InputField>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wk-row mt-2">
                                            <div className="wk-field wk-field-custom-select w-100">
                                                <div className="wk-field-header">
                                                    <label className="wk-field-label" htmlFor="input-id-pay-designation">Professional Designation*</label>
                                                </div>
                                                <div className="wk-field-body">
                                                    <div className="wk-field-select-container">
                                                        <SelectField errors={errors.designation} status={errors.designation ? 'error' : 'none'}>
                                                            <select
                                                                id="input-id-pay-designation"
                                                                name="designation"
                                                                placeholder="--Please Select--"
                                                                className='wk-field-select wk-input-border'
                                                                value={values.designation || ''}
                                                                onChange={handleChange}>
                                                                <option value={""}>--Please Select--</option>
                                                                {
                                                                    designation.map((d: any) => <option key={d.thinkDesignId} value={d.thinkDesignId}>{d.designation}</option>)
                                                                }
                                                            </select>
                                                        </SelectField>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wk-row mt-2">
                                            <div className="wk-field wk-field-custom-select w-100">
                                                <div className="wk-field-header">
                                                    <label className="wk-field-label" htmlFor="input-id-pay-specialty">Specialty*</label>
                                                </div>
                                                <div className="wk-field-body">
                                                    <div className="wk-field-select-container">
                                                        <SelectField errors={errors.speciality} status={errors.speciality ? 'error' : 'none'}>
                                                            <select
                                                                id="input-id-pay-specialty"
                                                                name="speciality"
                                                                placeholder="--Please Select--"
                                                                value={values.speciality || ''}
                                                                onChange={handleChange}>
                                                                <option value={""}>--Please Select--</option>
                                                                {
                                                                    specialities.map((s: any, index: number) => <option key={index} value={s}>{s}</option>)
                                                                }
                                                            </select>
                                                        </SelectField>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="wk-row mt-2">
                                            <div className="wk-field wk-field-custom-select w-100">
                                                <div className="wk-field-header">
                                                    <label className="wk-field-label" htmlFor="input-id-pay-licensure">State of Licensure</label>
                                                </div>
                                                <div className="wk-field-body">
                                                    <div className="wk-field-select-container">
                                                        <SelectField>
                                                            <select
                                                                id="input-id-pay-licensure"
                                                                name="licensure"
                                                                placeholder="--Please Select--"
                                                                value={values.licensure || ''}
                                                                onChange={handleChange}>
                                                                <option value={""}>--Please Select--</option>
                                                                {
                                                                    licensure.map((l: any) => <option key={l.id} value={l.id}>{l.name}</option>)
                                                                }
                                                            </select>
                                                        </SelectField>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="wk-row mt-2">
                                            <header className="w-100 wk-border-bottom wk-mt-26 wk-navbar-menu wk-text-left">
                                                <h3 className="wk-text-left create-your-account">Contact Details</h3>
                                            </header>
                                        </div>

                                        <div className="wk-row mt-2">
                                            <div className="wk-form-legend mailing-address mt-3">Mailing Address</div>
                                        </div>

                                        <div className="wk-row mt-2">
                                            <div className="col-sm-12 col-md-6 col-lg-6 pl-0 sm-pr-0">
                                                <div className="wk-field w-100">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-addr">Address*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className='wk-input-width'>
                                                            <InputField errors={errors.address} status={errors.address ? 'error' : 'none'}>
                                                                <input
                                                                    type="text"
                                                                    id="input-id-pay-addr"
                                                                    name="address"
                                                                    placeholder="Address1"
                                                                    className="wk-input-border wk-field-input"
                                                                    value={values.address || ''}
                                                                    onChange={handleChange}
                                                                />
                                                            </InputField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6 col-lg-6 pr-0 sm-pl-0">
                                                <div className="wk-field wk-field-with-tip w-100">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-addr2">Address2</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className='wk-input-width'>
                                                            <InputField>
                                                                <input
                                                                    type="text"
                                                                    id="input-id-pay-addr2"
                                                                    name="address2"
                                                                    placeholder="Address2"
                                                                    className="wk-input-border wk-field-input"
                                                                    value={values.address2 || ''}
                                                                    onChange={handleChange}
                                                                />
                                                            </InputField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="wk-row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 pl-0">
                                                <div className="wk-field wk-field-custom-select w-100" data-type="state">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-country">Country*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className="wk-field-select-container">
                                                            <SelectField errors={errors.country} status={errors.country ? 'error' : 'none'}>
                                                                <select
                                                                    id="input-id-pay-country"
                                                                    name="country"
                                                                    placeholder="--Please Select--"
                                                                    className="wk-input-border wk-field-input"
                                                                    value={values.country || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value={""}>--Please Select--</option>
                                                                    {
                                                                        country.map((c: any) => <option key={c.id} value={c.id}>{c.name}</option>)
                                                                    }
                                                                </select>
                                                            </SelectField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 pr-0 sm-pl-0">
                                                <div className="wk-field wk-field-custom-select w-100" data-type="state">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-state">State Name*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className="wk-field-select-container">
                                                            <SelectField errors={errors.state} status={errors.state ? 'error' : 'none'}>
                                                                <select
                                                                    id="input-id-pay-state"
                                                                    name="state"
                                                                    placeholder="--Please Select--"
                                                                    className="wk-input-border wk-field-input"
                                                                    value={values.state || ''}
                                                                    onChange={handleChange}>
                                                                    <option value={""}>--Please Select--</option>
                                                                    {
                                                                        state.map((s: any) => <option key={s.id} value={s.id}>{s.name}</option>)
                                                                    }
                                                                </select>
                                                            </SelectField>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="wk-row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 pl-0 sm-pr-0">
                                                <div className="wk-field w-100">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-city">City*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className='wk-input-width'>
                                                            <InputField errors={errors.city} status={errors.city ? 'error' : 'none'}>
                                                                <input
                                                                    type="text"
                                                                    id="input-id-pay-city"
                                                                    name="city"
                                                                    placeholder="Enter City"
                                                                    className="wk-input-border wk-field-input"
                                                                    value={values.city || ''}
                                                                    onChange={handleChange}
                                                                />
                                                            </InputField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6 col-lg-6 pr-0 sm-pl-0">
                                                <div className="wk-field wk-field-with-tip w-100 ">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-zipcode">Zip code*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className='wk-input-width'>
                                                            <InputField errors={errors.zipcode} status={errors.zipcode ? 'error' : 'none'}>
                                                                <input
                                                                    type="text"
                                                                    id="input-id-pay-zipcode"
                                                                    name="zipcode"
                                                                    placeholder="Enter Zipcode"
                                                                    className="wk-input-border wk-field-input"
                                                                    value={values.zipcode || ''}
                                                                    onChange={handleChange}
                                                                />
                                                            </InputField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wk-row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 pl-0 sm-pr-0">
                                                <div className="wk-field w-100">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-phone">Phone*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className='wk-input-width'>
                                                            <InputField errors={errors.phoneNumber} status={errors.phoneNumber ? 'error' : 'none'}>
                                                                <input
                                                                    type="text"
                                                                    id="input-id-pay-phone"
                                                                    name="phoneNumber"
                                                                    placeholder="999-999-9999"
                                                                    className="wk-input-border wk-field-input"
                                                                    value={values.phoneNumber || ''}
                                                                    onChange={handleChange}
                                                                />
                                                            </InputField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wk-col">
                                            </div>
                                        </div>

                                        <div className="wk-row">
                                            <div role="group" className="wk-field wk-field-small" aria-labelledby="group-id-1">
                                                <div className="wk-field-body">
                                                    <RadioGroupField>
                                                        <RadioField label={'Use as billing address'}>
                                                            <input
                                                                type="radio"
                                                                name="chkaddress"
                                                                value={'1'}
                                                                checked={values.chkaddress ? values.chkaddress === '1' : true}
                                                                onChange={handleChange}
                                                            />
                                                        </RadioField>
                                                        <RadioField label={'Use a different billing address'}>
                                                            <input
                                                                type="radio"
                                                                name="chkaddress"
                                                                value={'2'}
                                                                checked={values.chkaddress ? values.chkaddress === '2' : false}
                                                                onChange={handleChange}
                                                            />
                                                        </RadioField>
                                                    </RadioGroupField>
                                                </div>
                                            </div>
                                        </div>

                                        {values.chkaddress === '2' && <div id='billingAddress'>
                                            <div className="wk-row mt-2">
                                                <div className="wk-form-legend mailing-address mt-3">Billing Address</div>
                                            </div>

                                            <div className="wk-row mt-2">
                                                <div className="col-sm-12 col-md-6 col-lg-6 pl-0 sm-pr-0">
                                                    <div className="wk-field w-100">
                                                        <div className="wk-field-header">
                                                            <label className="wk-field-label" htmlFor="input-id-pay-billing-addr">Address*</label>
                                                        </div>
                                                        <div className="wk-field-body">
                                                            <div className='wk-input-width'>
                                                                <InputField errors={errors.billingAddress} status={errors.billingAddress ? 'error' : 'none'}>
                                                                    <input
                                                                        type="text"
                                                                        id="input-id-pay-billing-addr"
                                                                        name="billingAddress"
                                                                        placeholder="Address1"
                                                                        className="wk-input-border wk-field-input"
                                                                        value={values.billingAddress || ''}
                                                                        onChange={handleChange}
                                                                    />
                                                                </InputField>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6 pr-0 sm-pl-0">
                                                    <div className="wk-field wk-field-with-tip w-100">
                                                        <div className="wk-field-header">
                                                            <label className="wk-field-label" htmlFor="input-id-pay-billing-addr2">Address2</label>
                                                        </div>
                                                        <div className="wk-field-body">
                                                            <div className='wk-input-width'>
                                                                <InputField>
                                                                    <input
                                                                        type="text"
                                                                        id="input-id-pay-billing-addr2"
                                                                        name="billingAddress2"
                                                                        placeholder="Address2"
                                                                        className="wk-input-border wk-field-input"
                                                                        value={values.billingAddress2 || ''}
                                                                        onChange={handleChange}
                                                                    />
                                                                </InputField>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="wk-row">

                                                <div className="col-sm-12 col-md-6 col-lg-6 pl-0">
                                                    <div className="wk-field wk-field-custom-select w-100" data-type="state">
                                                        <div className="wk-field-header">
                                                            <label className="wk-field-label" htmlFor="input-id-pay-billing-country">Country*</label>
                                                        </div>
                                                        <div className="wk-field-body">
                                                            <div className="wk-field-select-container">
                                                                <SelectField errors={errors.billingCountry} status={errors.billingCountry ? 'error' : 'none'}>
                                                                    <select
                                                                        id="input-id-pay-billing-country"
                                                                        name="billingCountry"
                                                                        placeholder="--Please Select--"
                                                                        className="wk-input-border wk-field-input"
                                                                        value={values.billingCountry || ''}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value={""}>--Please Select--</option>
                                                                        {
                                                                            country.map((c: any) => <option key={c.id} value={c.id}>{c.name}</option>)
                                                                        }
                                                                    </select>
                                                                </SelectField>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 pr-0 sm-pl-0">
                                                    <div className="wk-field wk-field-custom-select w-100" data-type="state">
                                                        <div className="wk-field-header">
                                                            <label className="wk-field-label" htmlFor="input-id-pay-billing-state">State Name*</label>
                                                        </div>
                                                        <div className="wk-field-body">
                                                            <div className="wk-field-select-container">
                                                                <SelectField errors={errors.billingState} status={errors.billingState ? 'error' : 'none'}>
                                                                    <select
                                                                        id="input-id-pay-billing-state"
                                                                        name="billingState"
                                                                        placeholder="--Please Select--"
                                                                        className="wk-input-border wk-field-input"
                                                                        value={values.billingState || ''}
                                                                        onChange={handleChange}>
                                                                        <option value={""}>--Please Select--</option>
                                                                        {
                                                                            state.map((s: any) => <option key={s.id} value={s.id}>{s.name}</option>)
                                                                        }
                                                                    </select>
                                                                </SelectField>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="wk-row">

                                                <div className="col-sm-12 col-md-6 col-lg-6 pl-0 sm-pr-0">
                                                    <div className="wk-field w-100">
                                                        <div className="wk-field-header">
                                                            <label className="wk-field-label" htmlFor="input-id-pay-billing-city">City*</label>
                                                        </div>
                                                        <div className="wk-field-body">
                                                            <div className='wk-input-width'>
                                                                <InputField errors={errors.billingCity} status={errors.billingCity ? 'error' : 'none'}>
                                                                    <input
                                                                        type="text"
                                                                        id="input-id-pay-billing-city"
                                                                        name="billingCity"
                                                                        placeholder="Enter City"
                                                                        className="wk-input-border wk-field-input"
                                                                        value={values.billingCity || ''}
                                                                        onChange={handleChange}
                                                                    />
                                                                </InputField>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6 pr-0 sm-pl-0">
                                                    <div className="wk-field wk-field-with-tip w-100 ">
                                                        <div className="wk-field-header">
                                                            <label className="wk-field-label" htmlFor="input-id-pay-billing-zipcode">Zip code*</label>
                                                        </div>
                                                        <div className="wk-field-body">
                                                            <div className='wk-input-width'>
                                                                <InputField errors={errors.billingZipcode} status={errors.billingZipcode ? 'error' : 'none'}>
                                                                    <input
                                                                        type="text"
                                                                        id="input-id-pay-billing-zipcode"
                                                                        name="billingZipcode"
                                                                        placeholder="Enter Zipcode"
                                                                        className="wk-input-border wk-field-input"
                                                                        value={values.billingZipcode || ''}
                                                                        onChange={handleChange}
                                                                    />
                                                                </InputField>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="wk-row">
                                                <div className="col-sm-12 col-md-6 col-lg-6 pl-0 sm-pr-0">
                                                    <div className="wk-field w-100">
                                                        <div className="wk-field-header">
                                                            <label className="wk-field-label" htmlFor="input-id-pay-billing-phone">Phone*</label>
                                                        </div>
                                                        <div className="wk-field-body">
                                                            <div className='wk-input-width'>
                                                                <InputField errors={errors.billingPhoneNumber} status={errors.billingPhoneNumber ? 'error' : 'none'}>
                                                                    <input
                                                                        type="text"
                                                                        id="input-id-pay-billing-phone"
                                                                        name="billingPhoneNumber"
                                                                        placeholder="999-999-9999"
                                                                        className="wk-input-border wk-field-input"
                                                                        value={values.billingPhoneNumber || ''}
                                                                        onChange={handleChange}
                                                                    />
                                                                </InputField>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wk-col">
                                                </div>
                                            </div>
                                        </div>}

                                        <div className="wk-row">
                                            <legend className="font-weight-semibold wk-border-bottom">
                                                <span className="CreditCardInformation">Credit Card Information</span>
                                                <div className="header-elements float-right">
                                                    <img src="image/master.png" />
                                                    <img src="image/visa.png" />
                                                    <img src="image/amex.png" />
                                                </div>
                                            </legend>
                                        </div>

                                        <div className="wk-row mt-4">
                                            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
                                                <div className="wk-field w-100">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="ccContainer">Card Number*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className='wk-input-width'>
                                                            <InputField errors={errorCC} status={errorCC ? 'error' : 'none'}>
                                                                <div id="ccContainer" ref={ccNumberRef}></div>
                                                            </InputField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wk-row">
                                            <div className="col-sm-12 col-md-4 col-lg-4 pl-0 sm-pr-0">
                                                <div className="wk-field ">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="cvvContainer">CVV*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className='wk-input-width'>
                                                            <InputField errors={errorCVV} status={errorCVV ? 'error' : 'none'}>
                                                                <div id="cvvContainer" className="wk-field-input" ref={cvvRef}></div>
                                                            </InputField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-4 pl-0 sm-pr-0">
                                                <div className="wk-field wk-field-custom-select" data-type="state">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-month">Month*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className="wk-field-select-container">
                                                            <SelectField errors={errors.month} status={errors.month ? 'error' : 'none'}>
                                                                <select
                                                                    id="input-id-pay-month"
                                                                    name="month"
                                                                    className="wk-input-border wk-field-select"
                                                                    value={values.month || months[0]}
                                                                    onChange={handleChange}>
                                                                    {
                                                                        months.map((month: string, index: number) => <option key={`month${index}`} value={month}>{month}</option>)
                                                                    }
                                                                </select>
                                                            </SelectField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-4 pl-0 pr-0">
                                                <div className="wk-field wk-field-custom-select" data-type="state">
                                                    <div className="wk-field-header">
                                                        <label className="wk-field-label" htmlFor="input-id-pay-year">Year*</label>
                                                    </div>
                                                    <div className="wk-field-body">
                                                        <div className="wk-field-select-container">
                                                            <SelectField errors={errors.year} status={errors.year ? 'error' : 'none'}>
                                                                <select
                                                                    id="input-id-pay-year"
                                                                    name="year"
                                                                    value={values.year || years[0]}
                                                                    onChange={handleChange}>
                                                                    {
                                                                        years.map((year: string, index: number) => <option key={`year${index}`} value={year}>{year}</option>)
                                                                    }
                                                                </select>
                                                            </SelectField>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wk-row mb-5">
                                            <div className="actionButtons col-sm-12 col-md-12 col-lg-12 pl-0 text-right pr-0 sm-text-center">
                                                <ButtonField size={"small"} >
                                                    <button type="submit" className="wk-button Pay_pall_Bill">Pay</button>
                                                </ButtonField>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-1-custom"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        </>
    )
}

export default Payment;
